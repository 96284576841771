export const OPEN_SIDE_CHAT: string = 'OPEN_SIDE_CHAT';
export const CLOSE_SIDE_CHAT: string = 'CLOSE_SIDE_CHAT';
export const START_CHAT: string = 'START_CHAT';
export const START_CHAT_SUCCESS: string = 'START_CHAT_SUCCESS';
export const START_CHAT_FAILED: string = 'START_CHAT_FAILED';
export const REPLY_IN_CHAT: string = 'REPLY_IN_CHAT';
export const REPLY_IN_CHAT_SUCCESS: string = 'REPLY_IN_CHAT_SUCCESS';
export const REPLY_IN_CHAT_FAILED: string = 'REPLY_IN_CHAT_FAILED';
export const SET_CHAT_CONTEXT: string = 'SET_CHAT_CONTEXT';
export const ENABLE_ANIMATION: string = 'ENABLE_ANIMATION';
export const DISABLE_ANIMATION: string = 'DISABLE_ANIMATION';
export const RESTART_CHAT: string = 'RESTART_CHAT';
export const RESTART_CHAT_SUCCESS: string = 'RESTART_CHAT_SUCCESS';
export const RESTART_CHAT_FAILED: string = 'RESTART_CHAT_FAILED';
export const CLEAR_CHAT: string = 'CLEAR_CHAT';
export const SET_USER_REPLY: string = 'SET_USER_REPLY';
