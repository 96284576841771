import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as React from 'react';
import { CONVERSATIONS, MANAGE, PACKAGES } from '../../constants/paths';
import {
  BLUE_COLOR2,
  WHITE_COLOR,
  GREY_COLOR,
  LIGHT_GREY_COLOR2,
  LIGHT_BLUE_COLOR3,
  SMALL_FONT2,
  BLUE_COLOR,
} from '../../../styles/DefaultStylingProperties';
import { historyPush } from '../../history';
import { pageEnum } from '../pages/pageEnum';
import PluginSelectComponent from './PluginSelectComponent';
import eddiApiActionDispatchers from '../../actions/EddiApiActionDispatchers';

const useStyles = makeStyles({
  navBar: {
    width: 'fit-content',
    display: 'flex',
  },
  navBarItem: {
    borderBottom: `3px solid ${BLUE_COLOR2}`,
    color: WHITE_COLOR,
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: SMALL_FONT2,
    height: '39px',
    textAlign: 'center',
    width: '110px',
    lineHeight: '42px',

    '&:hover': {
      color: BLUE_COLOR,
      borderBottom: '3px solid ' + BLUE_COLOR,
    },
  },
  navBarItemDisabled: {
    borderBottom: '3px solid ' + LIGHT_GREY_COLOR2,
    color: GREY_COLOR,
  },
});

interface IProps {
  page: pageEnum;
}

const handleBots = () => {
  historyPush(MANAGE)
  eddiApiActionDispatchers.fetchBotsAction(5, 0);
}


const NavigationComponent = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.navBar}>
      <div
        key={'bots'}
        onClick={() => handleBots()}
        className={clsx(classes.navBarItem, {
          [classes.navBarItemDisabled]: props.page !== pageEnum.bot,
        })}>
        <div>{'Bots'}</div>
      </div>
      <div
        key={'packages'}
        onClick={() => historyPush(PACKAGES)}
        className={clsx(classes.navBarItem, {
          [classes.navBarItemDisabled]: props.page !== pageEnum.package,
        })}>
        <div>{'Packages'}</div>
      </div>
      <div
        key={'conversations'}
        onClick={() => historyPush(CONVERSATIONS)}
        className={clsx(classes.navBarItem, {
          [classes.navBarItemDisabled]: props.page !== pageEnum.conversation,
        })}>
        {'Conversations'}
      </div>
      <PluginSelectComponent page={props.page} />
    </div>
  );
};

export default NavigationComponent;
