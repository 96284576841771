export enum ModalEnum {
  'createBot',
  'editDescriptor',
  'createPackage',
  'updatePackage',
  'addPackages',
  'editJson',
  'addPlugins',
  'viewJson',
  'createNewConfig',
  'createNewConfig2',
  'updatePackages',
  'updateBots',
  'confirmation',
  'error',
  'addNewPackageToBot',
  'conversations',
  'basicAuth',
  'showBotLogs',
  'parallelConfig',
}
