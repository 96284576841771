export const BASIC_AUTH_SIGN_IN: string = 'BASIC_AUTH_SIGN_IN';
export const BASIC_AUTH_SIGN_IN_SUCCESS: string = 'BASIC_AUTH_SIGN_IN_SUCCESS';
export const BASIC_AUTH_SIGN_IN_FAILED: string = 'BASIC_AUTH_SIGN_IN_FAILED';

export const KEYCLOAK_SIGN_IN: string = 'INITIALIZE_KEYCLOAK';
export const KEYCLOAK_SIGN_IN_SUCCESS: string = 'KEYCLOAK_SIGN_IN_SUCCESS';
export const KEYCLOAK_SIGN_IN_FAILED: string = 'INITIALIZE_KEYCLOAK_FAILED';

export const KEYCLOAK_SIGN_OUT: string = 'KEYCLOAK_SIGN_OUT';

export const KEYCLOAK_REFRESH_TOKEN: string = 'KEYCLOAK_REFRESH_TOKEN';
export const KEYCLOAK_REFRESH_TOKEN_SUCCESS: string =
  'KEYCLOAK_REFRESH_TOKEN_SUCCESS';
export const KEYCLOAK_REFRESH_TOKEN_FAILED: string =
  'KEYCLOAK_REFRESH_TOKEN_FAILED';

export const SIGN_OUT: string = 'SIGN_OUT';
export const SIGN_OUT_SUCCESS: string = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILED: string = 'SIGN_OUT_FAILED';

export const CHECK_AUTHENTICATION: string = 'CHECK_AUTHENTICATION';
export const CHECK_AUTHENTICATION_SUCCESS: string =
  'CHECK_AUTHENTICATION_SUCCESS';
export const CHECK_AUTHENTICATION_FAILED: string =
  'CHECK_AUTHENTICATION_FAILED';
