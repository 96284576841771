const CONTEXT_EXAMPLE = `{
    "userId": {
      "type": "string",
      "value": "cdec53d4-9826-4a81-2w2w2-7d184bd6063f"
    },
    "userInfo": {
      "type": "object",
      "value": {
        "username": "Tom"
      }
    },
    "properties": {
      "type": "expressions",
      "value": "property(category_1(value)), property(category_2(value))"
    }
  }`;

export default CONTEXT_EXAMPLE;
