import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loadingWrapper: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    height: '500px',
    justifyContent: 'center',
  },
  deployExampleBotsButton: {
    marginTop: '5px',
    width: '160px',
  },
});

export default useStyles;
