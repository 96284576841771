import { makeStyles } from '@material-ui/core/styles';
import {
  BLUE_COLOR2,
  GREY_COLOR,
  MEDIUM_FONT,
  RED_COLOR,
} from '../../../styles/DefaultStylingProperties';

const useStyles = makeStyles({
  botPackageName: {
    color: BLUE_COLOR2,
    display: 'inline-block',
    fontFamily: 'Roboto',
    fontSize: MEDIUM_FONT,
    lineHeight: '19px',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  botPackageLastModifiedOn: {
    color: GREY_COLOR,
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '11px',
    whiteSpace: 'nowrap',
  },
  botPackageButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: BLUE_COLOR2,
    display: 'inline-block',
    minHeight: '50px',
    width: '150px',
    cursor: 'pointer',
  },
  updatePackages: {
    marginTop: '10px',
    width: '100px',
  },
  hasNewVersion: {
    color: RED_COLOR,
  },
});

export default useStyles;
