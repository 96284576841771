export enum pageEnum {
  'dictionary',
  'behavior',
  'output',
  'httpCalls',
  'langchain',
  'gitCalls',
  'property',
  'bot',
  'package',
  'conversation',
}
