export const SHOW_MODAL: string = 'SHOW_MODAL';
export const CLOSE_MODAL: string = 'CLOSE_MODAL';

export const SHOW_VIEW_JSON_MODAL: string = 'SHOW_VIEW_JSON_MODAL';
export const SHOW_EDIT_JSON_MODAL: string = 'SHOW_EDIT_JSON_MODAL';

export const SHOW_ADD_PLUGINS_MODAL: string = 'SHOW_ADD_PLUGINS_MODAL';
export const SHOW_ADD_PACKAGES_MODAL: string = 'SHOW_ADD_PACKAGES_MODAL';

export const SHOW_EDIT_DESCRIPTOR_MODAL: string = 'SHOW_EDIT_DESCRIPTOR_MODAL';
export const SHOW_CREATE_BOT_MODAL: string = 'SHOW_CREATE_BOT_MODAL';
export const SHOW_CREATE_PACKAGE_MODAL: string = 'SHOW_CREATE_PACKAGE_MODAL';
export const SHOW_UPDATE_PACKAGE_MODAL: string = 'SHOW_UPDATE_PACKAGE_MODAL';

export const SHOW_CREATE_NEW_CONFIG_MODAL: string =
  'SHOW_CREATE_NEW_CONFIG_MODAL';
export const SHOW_CREATE_NEW_CONFIG_2_MODAL: string =
  'SHOW_CREATE_NEW_CONFIG_2_MODAL';

export const SHOW_UPDATE_PACKAGES_MODAL: string = 'SHOW_UPDATE_PACKAGES_MODAL';
export const SHOW_UPDATE_BOTS_MODAL: string = 'SHOW_UPDATE_BOTS_MODAL';

export const SHOW_CONFIRMATION_MODAL: string = 'SHOW_CONFIRMATION_MODAL';
export const SHOW_ERROR_MESSAGE_MODAL: string = 'SHOW_ERROR_MESSAGE_MODAL';

export const SHOW_CONVERSATIONS_MODAL: string = 'SHOW_CONVERSATIONS_MODAL';

export const SHOW_BASIC_AUTH_MODAL: string = 'SHOW_BASIC_AUTH_MODAL';
export const SHOW_BOT_LOGS_MODAL: string = 'SHOW_BOT_LOGS_MODAL';
export const SHOW_PARALLEL_CONFIG_MODAL: string = 'SHOW_PARALLEL_CONFIG_MODAL';
